<template>
 <div>
  <curriculumDetailsNormal v-if="!$store.state.user.elderModel"/>
  <curriculumDetailsElder v-else/>
 </div>
</template>

<script>
import curriculumDetailsNormal from './curriculum-details-normal.vue'
import curriculumDetailsElder from './curriculum-details-elder.vue'
 export default {
   name: '',
   components: {
curriculumDetailsNormal,
curriculumDetailsElder
   },
   data () {
     return {

     }
   },
   computed: {

   },
   created() {

   },
   mounted() {

   },
   methods: {

   }
 }
</script>

<style  scoped>

 
</style>
