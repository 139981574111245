<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
        <span>课程详情</span>
      </div>
      <!-- 内容上 -->
      <div class="outermost-top">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(src, index) in item.pictureData" :key="index"
            ><div class="outermost-top-img">
              <img :src="src" alt="" /></div
          ></van-swipe-item>
        </van-swipe>
        <div class="top">
          <div class="outermost-top-title-type">
            {{ getType(item.courseType) }}
          </div>
          <div class="outermost-top-title">
            {{ item.courseTitle }}
          </div>
        </div>

        <div class="outermost-top-number outermost-top-content">
          <van-icon class="icon" size="14" name="user-o" />
          <span>名额：{{ item.quota }}人</span>
        </div>
        <div class="outermost-top-time outermost-top-content">
          <van-icon class="icon" size="14" name="clock-o" />
          <span>开课时间：{{ item.startTime }} 至 {{ item.endTime }} </span>
        </div>
        <div class="outermost-top-time outermost-top-content">
          <van-icon class="icon" size="14" name="clock-o" />
          <span>报名截止时间：{{ item.deadline }} </span>
        </div>
        <div class="outermost-top-time outermost-top-content">
          <van-icon
            class="icon"
            size="14"
            name="location-o"
          />
          <span>课程地址：{{ item.courseAddress }}</span>
        </div>
        <div class="outermost-top-time outermost-top-content">
          <van-icon class="icon" size="14" name="eye-o" />
          <span>浏览量：{{ item.viewNum }}</span>
        </div>
      </div>
      <!-- 内容中 -->
      <div class="outermost-middle">
        <div class="outermost-middle-title">
          <span>{{ item.issued }}</span>
          <van-tag round type="primary">发布单位</van-tag>
        </div>
        <div class="outermost-middle-content">
          <div>联系人：{{ item.contactPerson }}</div>
          <div>联系方式：{{ item.contactNumber }}</div>
        </div>
      </div>
      <!-- 内容下 -->
      <div class="outermost-bottom">
        <div class="outermost-bottom-title">课程简介</div>
        <div class="outermost-bottom-content">{{ item.textData }}</div>
      </div>

      <div class="outermost-button" @click="openApply(item.id)">
        <div :class="{ forbid: isForbid }">{{ activityDate }}</div>
      </div>
      <van-popup v-model="isShow" position="bottom" closeable>
        <div v-if="isShow">
          <h2 class="applyTitle">请填写报名信息</h2>
          <van-form @submit="onSubmit">
            <van-field
              v-model="username"
              required
              name="姓名"
              label="姓名"
              placeholder="请输入姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
              v-model="mobile"
              required
              name="手机"
              label="手机"
              placeholder="请输入手机号"
              :rules="userFormRules.mobile"
            />
            <van-field
              v-model="mailbox"
              required
              name="邮箱"
              label="邮箱"
              placeholder="请输入邮箱"
              :rules="userFormRules.mailbox"
            />
            <van-field
              v-model="company"
              name="公司"
              label="公司"
              placeholder="请输入公司名"
            />
            <van-field
              v-model="position"
              name="职位"
              label="职位"
              placeholder="请输入职位"
            />
            <van-field
              v-model="WeChat"
              name="微信"
              label="微信"
              placeholder="请输入微信"
            />
            <div style="margin: 16px">
              <van-button round block type="primary" native-type="submit"
                >提交</van-button
              >
            </div>
          </van-form>
        </div>
      </van-popup>
    </layout>
  </div>
</template>

<script>
import layout from '../../components/layout.vue'
import { getCouresDetails, openApply, pageviewCourse } from '@/api/course'
export default {
  components: {
    layout
  },
  data() {
    return {
      item: {},
      registered: 0,
      registrationStatus: 0,
      isShow: false,
      username: '',
      mobile: '',
      mailbox: '',
      company: '',
      position: '',
      WeChat: '',
      userFormRules: {
        mobile: [
          {
            required: true,
            message: '手机号不能为空'
          },
          {
            pattern: /^1[3|5|7|8]\d{9}$/,
            message: '手机号格式错误'
          }
        ],
        mailbox: [
          {
            required: true,
            message: '邮箱不能为空'
          },
          {
            pattern: /^\w+@\w+(\.\w+)+$/,
            message: '邮箱格式错误'
          }
        ]
      }
    }
  },
  methods: {
    getType(type) {
      switch (type) {
        case 0:
          return '创业培训'
        case 1:
          return '技能培训'
        case 2:
          return '适应性培训'
        case 3:
          return '岗位培训'
        case 4:
          return '岗前培训'
      }
    },
    pageviewCourse() {
      pageviewCourse({
        courseId: this.$route.params.id
      }).then((res) => {
        console.log(res)
      })
    },
    // 立即报名
    openApply(id) {
      if (this.isForbid || this.registered == 1) {
        return
      }
      this.isShow = true
      // openApply(id).then((res) => {
      //   console.log(res)
      //   if (res === true && typeof res != 'Object') {
      //     this.$toast.success('报名成功')
      //   } else {
      //     this.$toast.fail(res.message)
      //   }
      // })
    },
    onSubmit() {
      openApply({
        courseId: this.$route.params.id,
        company: this.company,
        email: this.mailbox,
        name: this.username,
        phone: this.mobile,
        position: this.position,
        weChat: this.WeChat
      }).then((res) => {
        this.isShow = false
        this.registered = 1
        this.$toast.success('报名成功')
      })
    },
    getCouresDetails() {
      getCouresDetails(this.$route.params.id).then((res) => {
        res.result.pictureData = res.result.pictureData.split(',')
        this.item = res.result
        this.registered = res.result.registered
        this.registrationStatus = res.result.registrationStatus
      })
    },
    // 返回上一页
    returnHome() {
      this.$router.go(-1)
    }
  },
  created() {
    this.getCouresDetails()
    this.pageviewCourse()
  },
  computed: {
    isForbid() {
      let present = new Date()
      // let start = new Date(this.item.startTime)
      // let end = new Date(this.item.endTime)
      let deadline = new Date(this.item.deadline)
      if (
        // present >= start &&
        // present < end &&
        present <= deadline &&
        this.registered != 1 &&
        parseInt(this.registrationStatus) < parseInt(this.item.quota)
      ) {
        return false
      } else if (
        present >= deadline ||
        this.registered == 1 ||
        parseInt(this.registrationStatus) >= parseInt(this.item.quota)
      ) {
        return true
      }
    },
    activityDate() {
      let present = new Date()
      let deadline = new Date(this.item.deadline)
      // let start = new Date(this.item.startTime)
      // let end = new Date(this.item.endTime)
      // if (present < start) {
      //   return '课程未开始'
      // } else if (present > end) {
      //   return '课程已结束'
      // } else
      if (present > deadline) {
        return '报名已截至'
      } else if (
        parseInt(this.registrationStatus) >= parseInt(this.item.quota)
      ) {
        return '报名人数已满'
      } else if (this.registered == 1) {
        return '已报名'
      } else {
        return '立即报名'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  right: 0;
  left: 0;
  .applyTitle {
    text-align: center;
  }

  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #f3f3f3;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
      color: #ffffff;
    }
  }
  .outermost-top {
    background-color: #fff;
    padding: 12px 16px;
    .top {
      align-items: flex-end;
      display: flex;
    }
    .outermost-top-title-type {
      padding: 0 10px;
      height: 20px;
      background: linear-gradient(90deg, #25c794, #20ce82, #14ab69 100%);
      border-radius: 12px 0px 12px 0px;
      text-align: center;
      line-height: 20px;
      display: inline-block;
      margin-right: 5px;
      font-size: 18px;
      color: #ffffff;
    }
    .outermost-top-img {
      height: 193px;
      img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
    }
    .outermost-top-title {
      margin-top: 13px;
      font-size: 20px;
      color: #12151b;
      .outermost-top-title-type {
        width: 60px;
        height: 30px;
        border-radius: 12px 0px 12px 0px;
        text-align: center;
        line-height: 20px;
        display: inline-block;
        margin-right: 5px;
        font-size: 16px;
        color: #ffffff;
      }
    }
    .outermost-top-content {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #464a55;
      .icon {
        margin-right: 8px;
      }
      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
    }
    .outermost-top-number {
      margin-top: 6px;
    }
    .outermost-top-time {
      margin-top: 4px;
    }
  }
  .outermost-middle {
    background-color: #fff;
    padding: 12px 16px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .outermost-middle-title {
      display: flex;
      align-items: center;
      span {
        font-size: 20px;
        color: #12151b;
      }
      /deep/ .van-tag {
        background: #e4f0ff;
        font-size: 16px;
        color: #2083ff;
        padding: 3px 7px;
        margin-left: 8px;
      }
    }
    .outermost-middle-content {
      width: 500px;
      margin-top: 8px;
      display: flex;
      font-size: 16px;
      color: #464a55;
      div:nth-child(1) {
        margin-right: 50px;
      }
    }
  }
  .outermost-bottom {
    background-color: #fff;
    padding: 12px 16px;
    margin-top: 8px;
    .outermost-bottom-title {
      font-size: 20px;
      color: #000000;
    }
    .outermost-bottom-content {
      margin-top: 8px;
      font-size: 18px;
      color: #464a55;
    }
  }
  .outermost-button {
    background-color: #fff;
    padding: 9px 58px;
    margin-top: 5px;
    div {
      height: 40px;
      background: linear-gradient(90deg, #25c794, #20ce82, #14ab69 100%);
      border-radius: 8px;
      box-shadow: 0px 4px 10px 0px rgba(12, 162, 92, 0.3);
      line-height: 40px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .forbid {
    background: linear-gradient(
      90deg,
      #494949,
      #888888,
      #444444 100%
    ) !important;
    box-shadow: none !important;
    pointer-events: none;
  }
}
</style>